<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  transactions: {
    type: Array,
    default: () => [],
  },
  show_only_list: {
    type: Boolean,
    default: false,
  },
});
const { route, router } = useCommonImports();
const inventory_store = useInventoryStore();

function onTransactionClick(transaction) {
  if (props.show_only_list)
    router.push({ name: 'inventory-transactions', query: { transaction_number: transaction.number }, params: { ...route.params } });
  else
    inventory_store.active_transaction_uid = transaction.uid;
}
</script>

<template>
  <div
    v-for="(transaction, index) in transactions" :id="transaction.number"
    :key="transaction"
    class="p-4 hover:bg-gray-50 cursor-pointer "
    :class="[{ 'border-b': index + 1 !== transactions?.length },
             { 'hover:rounded-t-lg': index === 0 },
             { 'hover:rounded-b-lg': index + 1 === transactions?.length },
             { 'bg-gray-50': (inventory_store.active_transaction_uid === transaction.uid) && !show_only_list },
    ]"
    @click="onTransactionClick(transaction)"
  >
    <div class="flex justify-between mb-2">
      <div>
        <div class="font-medium mb-1">
          {{ transaction?.number }}
        </div>
        <div class="text-xs">
          {{ $date(transaction?.date, 'DD MMMM YYYY h:mm A') }}
        </div>
      </div>
      <div class="flex gap-2">
        <HawkBadge v-if="transaction?.status === 'draft'" type="dark" color="orange">
          {{ $t('Draft') }}
        </HawkBadge>
        <HawkBadge :custom_color="inventory_store.workflows_map[transaction.workflow]?.color" custom_classes="whitespace-nowrap">
          {{ inventory_store.workflows_map[transaction.workflow]?.name }}
        </HawkBadge>
      </div>
    </div>
    <div v-if="show_only_list" class="flex gap-2 text-sm items-center">
      <IconHawkMarkerPinOne class="h-4 w-4" />
      <HawkText :content="transaction.from_stock.name" />
      <IconHawkArrowNarrowRight class="h-4 w-4" />
      <HawkText :content="transaction.to_stock.name" />
    </div>
    <div v-else class="flex gap-2 text-sm items-center justify-between">
      <div class="flex gap-2 items-center" :class="{ 'w-1/2': inventory_store.workflows_map[transaction?.workflow]?.stock_operation !== 'no_op' }">
        <IconHawkMarkerPinOne class="h-4 w-4 flex-shrink-0" />
        <HawkText :content="transaction.from_stock.name" :length="inventory_store.workflows_map[transaction?.workflow]?.stock_operation === 'no_op' ? 32 : 15" />
      </div>
      <template v-if="inventory_store.workflows_map[transaction?.workflow]?.stock_operation !== 'no_op'">
        <IconHawkArrowNarrowRight class="h-4 w-4 flex-shrink-0" />
        <HawkText :content="transaction.to_stock.name" :length="15" class="w-1/2" />
      </template>
    </div>
  </div>
</template>
