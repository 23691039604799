<script setup>
import { isEqual, orderBy } from 'lodash-es';
import { computed, onMounted, reactive } from 'vue';
import AssetResources from '~/acct-settings/components/acct-settings-asset-resources/acct-settings-asset-resources.vue';
import AcctSettingsUserContextMenu from '~/acct-settings/components/acct-settings-user-context-menu.vue';
import HawkRolesInput from '~/common/components/vueform/hawk-roles-input.vue';
import HawkTeamsInput from '~/common/components/vueform/hawk-teams-input.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { getUserFullName } from '~/common/utils/common.utils.js';

const form$ = ref(null);
const asset_access$ = ref(null);

const { $t, $date, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

const state = reactive({
  is_loading: false,
  is_updating: false,
  user_details: {},
  permissions: {},
});

const can_modify_users = computed(() => auth_store.check_permission('modify_users', route.params.asset_id));
const is_disabled = computed(() => !asset_access$.value?.getValue()?.is_valid);
const user_info = computed(() => {
  const { active, email, phone_number, updated_at = 'Not found', organization } = state.user_details;
  const user_status = active ? $t('Active') : $t('Deactivated');

  const getType = () => {
    if (state.user_details?.is_owner)
      return $t('Owner');
    if (state.user_details?.is_manager)
      return $t('Global admin');
    if (state.user_details?.admin_assets?.length)
      return $t('Asset admin');
    if (state.user_details?.user_type === 'guest')
      return $t('Guest');
    return 'Member';
  };

  let full_name = '';

  if (state.user_details?.first_name && state.user_details?.last_name)
    full_name = `${state.user_details?.first_name} ${state.user_details?.last_name}`;

  return {
    'Name': full_name,
    'Email Address': email,
    'Phone number': phone_number,
    'Type': getType(),
    'Last Active on': $date(updated_at, 'DATETIME_MED'),
    'Organization': common_store.get_organization(organization)?.name || 'NA',
    'Status': state.user_details?.status === 'invited' ? $t('Invited') : user_status,
  };
});

function formatTeams(uids = []) {
  uids = uids || [];
  return orderBy(uids.reduce((items, uid) => {
    if (common_store.teams_map[uid]) {
      items.push({
        uid,
        name: common_store.teams_map[uid].name,
        default: common_store.teams_map[uid].default,
      });
    }

    return items;
  }, []), 'default', 'desc');
}

async function getData() {
  try {
    state.is_loading = true;
    const { data } = await $services.users_v2.get({
      asset_id: route?.params?.asset_id,
      id: route?.params?.id,
    });
    state.user_details = data.user;
    state.permissions = state.user_details.permissions;
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log(error);
  }
}

function goBack() {
  if (route.name === 'account-settings-invites-user-details')
    router.push({ name: 'account-settings-invites' });

  else
    router.push({ name: 'account-settings-users' });
}

async function save() {
  try {
    const form_data = form$.value.data;
    const payload = {
      ...state.user_details,
      ...form_data,
      ...asset_access$.value.getValue(),
    };

    payload.teams = form_data?.teams.map(team => team.uid);
    payload.roles = form_data?.roles.map(role => role.uid);
    payload.permissions = state.permissions;

    const response = await $services.users_v2.patch({
      asset_id: route?.params?.asset_id,
      id: payload.uid,
      body: { user: payload },
      ...(route?.params?.asset_id ? { query: { asset: route.params.asset_id } } : {}),
    });

    if (response.data?.user) {
      $toast({
        title: $t('User updated'),
        text: $t('Your changes to the user have been successfully saved'),
        type: 'success',
      });
      if (!isEqual(state.user_details.permissions, payload.permissions)) {
        $track_event('permissions_changed', {
          type: 'Permissions were changed',
          where: 'User details',
        });
      }
      if (payload.roles?.length && !isEqual(state.user_details.roles, payload.roles)) {
        $track_event('permissions_changed', {
          type: 'Role was assigned',
          where: 'User details',
        });
      }

      await common_store.update_global_data({ users: true });
      goBack();
    }
    else {
      $toast({
        title: $t('Failed to update user'),
        text: $t('Unable to update the user. Please try again'),
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.error('[DEBUG] acct-settings-user-details.vue::152\n', error);
    $toast({
      title: $t('Failed to update user'),
      text: $t('Unable to update the user. Please try again'),
      type: 'error',
    });
  }
}

function onClear() {
  setTimeout(() => {
    form$.value.update({
      ...form$.value?.data,
      teams: formatTeams(state.user_details?.teams)?.filter(team => team.default),
    });
  }, 1);
}

onMounted(async () => await getData());
</script>

<template>
  <HawkLoader v-if="state.is_loading" />
  <div v-else class="py-4">
    <HawkPageSecondaryHeader class="w-full">
      <template #left>
        <div class="flex items-center gap-3">
          <HawkButton type="text" icon @click="router.push({ name: 'account-settings-users' })">
            <IconHawkChevronLeft />
          </HawkButton>
          <div>
            <p class="text-lg font-semibold text-gray-900 leading-none">
              {{ getUserFullName(state.user_details) }}
            </p>
            <span class="text-xs text-gray-600">{{ state.user_details?.email }}</span>
          </div>
        </div>
      </template>
      <template #right>
        <AcctSettingsUserContextMenu
          v-if="can_modify_users"
          :user="state.user_details"
          @loading="state.is_updating = $event"
          @update="getData()"
        >
          <template #trigger>
            <HawkButton type="outlined" icon :loading="state.is_updating">
              <IconHawkDotsVertical />
            </HawkButton>
          </template>
        </AcctSettingsUserContextMenu>
      </template>
    </HawkPageSecondaryHeader>
    <HawkIllustrations v-if="!can_modify_users" type="no-permission" />
    <div v-else class="mt-4">
      <Vueform
        ref="form$"
        size="sm"
        :display-errors="false"
        :columns="{ container: 12, label: 6, wrapper: 12 }"
        :endpoint="save"
      >
        <div class="col-span-12">
          <div class="max-w-[700px]">
            <div class="text-sm gap-4 grid">
              <div class="flex gap-10 mb-1">
                <div class="w-1/2 text-sm font-medium text-gray-700">
                  {{ $t("Profile picture") }}
                </div>
                <div class="w-1/2">
                  <HawkMembers :members="state.user_details?.uid" size="xl" />
                </div>
              </div>

              <div v-for="[label, value] of Object.entries(user_info)" :key="label" class="flex gap-2">
                <div class="w-1/2 text-sm font-medium text-gray-700">
                  {{ $t(label) }}
                </div>
                <div v-if="label === 'Status'" class="w-1/2 text-gray-900">
                  <HawkBadge :color="state.user_details.active ? 'green' : 'gray'">
                    {{ value }}
                  </HawkBadge>
                </div>
                <div v-else class="w-1/2 text-gray-900">
                  {{ value || '-' }}
                </div>
              </div>
            </div>
          </div>
          <hr class="my-5">

          <div class="max-w-[700px]">
            <div class="mb-6">
              <p class="text-sm font-semibold text-gray-900 leading-none">
                {{ $t('Teams') }}
              </p>
              <span class="inline-block text-xs text-gray-600 mb-2">
                {{ $t('Choose teams to assign to the user. The user will also inherit permissions from the team\'s roles if they are added to the team.') }}
              </span>
              <HawkTeamsInput
                :key="state.user_details?.teams?.length"
                v-bind="{
                  multi: true,
                  options: {
                    columns: {
                      sm: { container: 8, label: 4, wrapper: 8 },
                    },
                    name: 'teams',
                    default: formatTeams(state.user_details?.teams),
                    placeholder: $t('Select teams'),
                    existing_assets: state.user_details?.teams || [],
                    canClear: !(form$?.data?.teams.length === 1 && form$?.data?.teams[0]?.default),
                  },
                }"
                @clear="onClear($event)"
              />
            </div>
            <div class="mb-6">
              <p class="text-sm font-semibold text-gray-900 leading-none">
                {{ $t('Roles') }}
              </p>
              <span class="inline-block text-xs text-gray-600 mb-2">
                {{ $t('Create or select roles to add this member') }}
              </span>
              <HawkRolesInput
                :key="state.user_details?.roles?.length"
                v-bind="{
                  multi: true,
                  options: {
                    columns: {
                      sm: { container: 8, label: 4, wrapper: 8 },
                    },
                    name: 'roles',
                    default: state.user_details?.roles?.map(role => ({ uid: role })),
                    placeholder: $t('Select roles'),
                    existing_assets: state.user_details?.roles || [],
                  },
                }"
              />
            </div>
          </div>
          <AssetResources
            v-if="!state.is_loading"
            ref="asset_access$"
            :admin_assets="state.user_details.admin_assets"
            :asset_permissions="state.user_details.resources"
            :access_all_assets="state.user_details.access_all_assets"
          />
          <hr class="my-5">
          <AcctPermissions v-model="state.permissions" />
          <template v-if="state.user_details?.uid">
            <hr class="my-5">
            <AcctPermissionsSummary :is-global-admin="state.user_details?.is_manager" :is-owner="state.user_details?.is_owner" />
          </template>
          <div class="sticky bg-white bottom-0 z-[11]">
            <hr class="my-5">
            <div class="flex justify-end gap-3 pb-5">
              <HawkButton
                type="outlined"
                @click="goBack()"
              >
                {{ $t("Cancel") }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :disabled="is_disabled"
                :submits="true"
              >
                {{ route.params.id ? $t("Update") : $t("Save") }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
