<script setup>
import DOMPurify from 'dompurify';
import { capitalize, flatMap, groupBy, has, keyBy, keys, mapValues, max, uniq } from 'lodash-es';
import { permissionsMatrix } from '~/acct-settings/components/acct-permissions/permissions-matrix.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

defineProps({
  isGlobalAdmin: { type: Boolean, default: false },
  isOwner: { type: Boolean, default: false },
});
const { $services, route, $t } = useCommonImports();
const permissions_matrix = permissionsMatrix($t);
const COLUMNS = [
  { label: 'Basic', key: 'basic', max: 0 },
  { label: 'Template Manager', key: 'templates_manager', max: 1 },
  { label: 'Limited', key: 'limited', max: 2 },
  { label: 'Advanced', key: 'advanced', max: 3 },
  { label: 'Manager', key: 'manager', max: 4 },
  { label: 'Admin', key: 'admin', max: 5 },
];

const module_name = {
  documents: 'DMS',
  reports: 'Dashboards',
};

const state = reactive({
  is_loading: false,
  assets_summary: [],
  permissions_summary: {},
  permissions_metadata: {},
  permissions: [],
  resources_map: {},
});

function setResourceMap(data) {
  data.forEach((element) => {
    if (element?.asset_resources?.length) {
      element?.asset_resources.forEach((resource) => {
        if (has(state.resources_map, resource.uid)) {
          state.resources_map[resource.uid]?.details.push(element);
        }
        else {
          state.resources_map[resource.uid] = {
            name: resource.name,
            uid: resource.uid,
            details: [element],
          };
        }
      });
    }
  });
}

async function getData() {
  try {
    state.is_loading = true;

    const { data } = await $services.permissions_v2.permissions_summary({
      id: route?.params?.id,
      ...(route.params.asset_id ? { asset_id: route?.params?.asset_id } : {}),
    });

    setResourceMap(data.assets);
    const grouped_asset = mapValues(groupBy(data.assets, 'asset'), items => groupBy(items, 'resource_access'));
    state.assets_summary = grouped_asset;

    for (const [key] of Object.entries(state.permissions_metadata)) {
      state.permissions_summary[key] = data.permissions[key];
    }
    const column_key = keyBy(COLUMNS, 'key');
    for (const key in state.permissions_summary) {
      const value = state.permissions_summary[key] || [];
      state.permissions_metadata[key].max = max(uniq(value.map(val => column_key[val.permission.toLowerCase()]?.max)));
    }

    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ getData ~ error:', error);
  }
}

function columnData() {
  const get_status = (column_data, permission, index, max) => {
    // show hyphen - No permissions
    if (!column_data)
      return 0;
    // show cross - permissions, no set
    else if (!permission.length)
      return 1;
    // show green tick - permissions, highest value
    else if (max === index)
      return 3;
    // show grey tick - permissions, set
    return 2;
  };

  for (const permission_key in state.permissions_summary) {
    const _permission = {
      module: permission_key,
      columns: COLUMNS.map((column, index) => {
        const column_data = permissions_matrix.find(pm => pm.value === permission_key)?.columns.find(c => c.value === column.key);
        const permission_value = state.permissions_summary?.[permission_key]?.filter(permission => permission?.permission?.toLowerCase() === column.key) || [];
        return {
          status: get_status(column_data, permission_value, index, state.permissions_metadata[permission_key]?.max),
          value: permission_value,
          header: column.key,
        };
      }),
    };
    state.permissions.push(_permission);
  }
}

function getTooltipContent(value) {
  let tooltip_text = '';
  tooltip_text = value?.map((val) => {
    if (val.type === 'direct')
      return '<span class="text-xs font-semibold">Direct user permission</span>';
    return `<span class="text-xs font-semibold">${capitalize(val?.resource_type)}: ${capitalize(val?.resource_name)}</span>`;
  });
  return tooltip_text?.length ? DOMPurify.sanitize(tooltip_text?.join('<br/>')) : '';
}

function getResourcesValue(item) {
  const noResourcesItems = item?.filter(resource => !resource.asset_resources.length) || [];
  const flattenedResources = flatMap(item, 'asset_resources');
  return {
    ...groupBy(flattenedResources, 'uid'),
    ...(noResourcesItems?.length ? { ___no___: noResourcesItems } : {}),
  };
}

onMounted(async () => {
  await permissions_matrix.forEach((item) => {
    state.permissions_metadata[item.value] = { key: item.value };
  });
  await getData();
  columnData();
});
</script>

<template>
  <div class="mb-5 text-sm font-semibold text-gray-900">
    {{ $t('Permissions summary') }}
  </div>
  <HawkLoader v-if="state.is_loading" />
  <div v-else class="grid gap-5">
    <div v-if="keys(state.assets_summary).length || isOwner || isGlobalAdmin">
      <table class="w-full">
        <thead class="bg-gray-50">
          <tr>
            <th class="w-2/12">
              {{ $t('Assets') }}
            </th>
            <th class="w-10/12">
              {{ $t('Maps & Layers') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="isOwner">
          <tr>
            <td colspan="2">
              <div class="flex gap-2 items-center">
                <HawkBadge color="orange">
                  {{ $t('Owner') }}
                </HawkBadge>
                <span class="text-xs">{{ $t('Full access to all assets') }}</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isGlobalAdmin">
          <tr>
            <td colspan="2">
              <div class="flex gap-2 items-center">
                <HawkBadge color="yellow">
                  {{ $t('Global admin') }}
                </HawkBadge>
                <span class="text-xs">{{ $t('Full access to all assets') }}</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(asset, uid) in state.assets_summary" :key="asset.asset">
            <td>
              <HawkAssetName :uid="uid" :length="100" asset_class="break-all" />
            </td>
            <td>
              <div class="flex">
                <div v-for="(item, item_key, index) in asset" :key="item.id" class="flex">
                  <div
                    v-if="item_key === 'undefined' && item?.length === 1 && item[0]?.asset_admin"
                    v-tippy="{ content: getTooltipContent(item), placement: 'bottom', allowHTML: true }"
                    class="cursor-pointer"
                  >
                    <HawkBadge color="blue">
                      {{ $t('Asset admin') }}
                    </HawkBadge>
                  </div>
                  <div
                    v-else-if="item_key === 'full'"
                    v-tippy="{ content: getTooltipContent(item), placement: 'bottom', allowHTML: true }"
                    class="cursor-pointer hover:underline"
                  >
                    {{ $t('Full Access') }}
                  </div>
                  <div
                    v-else-if="['no', 'null'].includes(item_key)"
                    v-tippy="{ content: getTooltipContent(item), placement: 'bottom', allowHTML: true }"
                    class="cursor-pointer hover:underline"
                  >
                    {{ $t('No Access') }}
                  </div>
                  <template v-else-if="item_key === 'limited'">
                    <div
                      v-for="(resource, resource_key, resource_index) in getResourcesValue(item)" :key="resource.id"
                      class="cursor-pointer hover:underline"
                    >
                      <span
                        v-if="state.resources_map[resource_key]?.name"
                        v-tippy="{ content: getTooltipContent(state.resources_map[resource_key]?.details),
                                   placement: 'bottom',
                                   allowHTML: true }"
                      >
                        <span>{{ state.resources_map[resource_key]?.name || 'noAccess' }}</span>
                      </span>

                      <span
                        v-else
                        v-tippy="{ content: getTooltipContent(resource),
                                   placement: 'bottom',
                                   allowHTML: true }"
                      >
                        {{ $t('No Access') }}
                      </span>

                      <span v-if="resource_index !== keys(getResourcesValue(item))?.length - 1" class="mr-1">,</span>
                    </div>
                  </template>
                  <span v-if="index !== (keys(asset)?.length - 1)" class="mr-1">,</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <table class="table-fixed w-full">
        <thead class="bg-gray-50">
          <tr>
            <th class="w-2/12">
              {{ $t('Applications') }}
            </th>
            <th v-for="(column, index) in COLUMNS" :key="column.key" class="!text-center !border-l-0" :class="{ '!border-r-0': index !== COLUMNS.length - 1 }">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permission in state.permissions" :key="permission.module">
            <td class="capitalize">
              {{ module_name[permission.module] || permission.module }}
            </td>
            <td v-for="(column, index) in permission.columns" :key="column" class="!border-l-0" :class="{ '!border-r-0': index !== permission.columns.length - 1 }">
              <span v-if="column?.status === 0" class="flex justify-center">
                -
              </span>
              <div
                v-else-if="column?.status === 1"
                class="flex justify-center opacity-30"
              >
                <IconHawkXTwo />
              </div>
              <div
                v-else-if="column?.status === 2"
                v-tippy="{ content: getTooltipContent(column.value), placement: 'bottom', allowHTML: true }"
                class="flex justify-center cursor-pointer"
              >
                <IconHawkCheckThree />
              </div>
              <div
                v-else-if="column?.status === 3"
                v-tippy="{ content: getTooltipContent(column.value), placement: 'bottom', allowHTML: true }"
                class="flex justify-center cursor-pointer"
              >
                <IconHawkCheckFour />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
table {
  border-collapse: collapse;
}

th,td {
  @apply border text-left;
}

th{
  @apply text-xs font-medium py-2 px-3 text-gray-600;
}
td{
  @apply text-sm font-medium py-2 px-3;
}
</style>
