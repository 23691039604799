<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep, filter, isEqual, keyBy, orderBy } from 'lodash-es';
import { computed } from 'vue';
import AssetResourcesInput from '~/acct-settings/components/acct-settings-asset-resources/acct-settings-asset-resources-access-input.vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  admin_assets: {
    type: Array,
    default: () => ([]),
  },
  asset_permissions: {
    type: Array,
    default: () => ([]),
  },
  access_all_assets: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update', 'updateAccessAllAssets']);
const $t = inject('$t');
const common_store = useCommonStore();

const state = reactive({
  force_update: 0,
  items: [
    { uid: 'all', label: $t('All assets') },
    { uid: 'enabled', label: $t('Enabled') },
    { uid: 'disabled', label: $t('Disabled') },
  ],
  resource_access: [
    { uid: 'no', label: $t('No access') },
    { uid: 'limited', label: $t('Limited access') },
    { uid: 'full', label: $t('Full access') },
  ],
  filter: 'all',
  asset_forms: {},
  asset_forms_before_toggle: {},
});
const form = ref({});

const items_map = computed(() => keyBy(state.items, 'uid'));
const resources_map = computed(() => keyBy(props.asset_permissions, 'asset'));
const filtered_assets = computed(() => orderBy(
  filter(common_store.assets, (asset) => {
    const asset_form_enabled = state.asset_forms[asset.uid]?.has_access;
    return state.filter === 'all' || (asset_form_enabled ? state.filter === 'enabled' : state.filter === 'disabled');
  }),
  ['name'],
  ['asc'],
),
);

function getAssetsData() {
  let access_all_assets = true;
  const resources = Object.keys(state.asset_forms).reduce((acc, asset_uid) => {
    const form = state.asset_forms[asset_uid];
    access_all_assets = access_all_assets && form.has_access;
    if (form.has_access) {
      acc.push({
        asset: asset_uid,
        resources: form.resources || [],
        resource_access: form.resource_access,
      });
    }
    return acc;
  }, []);
  return {
    access_all_assets,
    resources,
    is_valid: resources.every(r => (r.resource_access !== 'limited' || r.resources?.length > 0)),
  };
}

function onUpdate(asset, e) {
  if (isEqual(state.asset_forms[asset.uid], e))
    return;
  state.asset_forms[asset.uid] = e;
  const { access_all_assets, assets } = getAssetsData();
  if (access_all_assets)
    state.asset_forms_before_toggle[asset.uid] = cloneDeep(e);
  else
    state.asset_forms_before_toggle = cloneDeep(state.asset_forms);
  form.value.access_all_assets = access_all_assets;
  emit('update', { assets });
  emit('updateAccessAllAssets', form.value.access_all_assets);
}

function onAccessToggle() {
  if (form.value.access_all_assets)
    state.asset_forms_before_toggle = cloneDeep(state.asset_forms);
  common_store.assets.forEach((asset) => {
    state.asset_forms[asset.uid] = {
      ...(state.asset_forms?.[asset.uid] || {}),
      has_access: form.value.access_all_assets || Boolean(state.asset_forms_before_toggle?.[asset.uid]?.has_access),
    };
  });
  state.force_update++;
  emit('updateAccessAllAssets', form.value.access_all_assets);
}

function getAssetFormData(asset) {
  const form_data = state.asset_forms[asset.uid];
  const has_access = form_data?.has_access || form.value.access_all_assets || Boolean(resources_map.value[asset.uid]);
  const asset_data = resources_map.value[asset.uid] || {};
  const is_manager = props.admin_assets.includes(asset.uid);
  return {
    has_access,
    resource_access: is_manager ? 'full' : asset_data.resource_access,
    resources: asset_data.resources || [],
    is_manager,
  };
}

function bulkUpdateResourceAccess(access) {
  Object.keys(state.asset_forms).forEach((asset_id) => {
    if (state.asset_forms[asset_id]?.has_access && !state.asset_forms[asset_id]?.is_manager)
      state.asset_forms[asset_id].resource_access = access;
  });
  state.force_update++;
  const { assets } = getAssetsData();
  emit('update', { assets });
}

function init() {
  form.value = { access_all_assets: props.access_all_assets };
  common_store.assets.forEach((asset) => {
    state.asset_forms[asset.uid] = getAssetFormData(asset);
  });
  state.asset_forms_before_toggle = cloneDeep(state.asset_forms);
  state.force_update++;
}
init();
defineExpose({ getAssetsData });
</script>

<template>
  <div>
    <Vueform
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      sync class="sticky top-[124px] bg-white z-[10]"
    >
      <div class="col-span-6 mb-6">
        <ToggleElement name="access_all_assets" :label="$t('Grant access to all assets')" @change="onAccessToggle" />
      </div>
    </Vueform>
    <div class="w-full">
      <div class="grid grid-cols-12 items-center text-xs font-medium mb-2 sticky top-[168px] bg-white border-b z-[10] pb-2">
        <div class="col-span-4 ml-12">
          {{ $t('ASSET') }}
        </div>
        <div class="col-span-8 flex items-center justify-between">
          <HawkMenu
            :items="state.resource_access.map(item => ({ ...item, on_click: () => bulkUpdateResourceAccess(item.uid) }))"
            additional_trigger_classes="!ring-0 !border-0"
            position="fixed"
          >
            <template #trigger="{ open }">
              <hawk-button type="text">
                <div class="text-gray-500 text-xs">
                  {{ $t('MAPS/LAYERS') }}
                </div>
                <IconHawkChevronDown
                  class="text-white transition-transform"
                  :class="{ 'rotate-180': open }"
                />
              </hawk-button>
            </template>
          </HawkMenu>
          <HawkMenu
            :items="state.items.map(item => ({ ...item, on_click: () => (state.filter = item.uid) }))"
            additional_trigger_classes="!ring-0 !border-0"
            position="fixed"
          >
            <template #trigger="{ open }">
              {{ $t('Show') }}:
              <HawkButton type="text">
                {{ items_map[state.filter].label }}
                <IconHawkChevronDown
                  class="transition-transform"
                  :class="{ 'rotate-180': open }"
                />
              </HawkButton>
            </template>
          </HawkMenu>
        </div>
      </div>
      <div v-for="(asset, index) in filtered_assets" :key="asset.uid">
        <AssetResourcesInput
          :key="`${state.force_update}_${asset.uid}`"
          :asset_uid="asset.uid"
          display_type="org-level"
          :form_data="state.asset_forms[asset.uid]"
          @update="onUpdate(asset, $event)"
          @form-initialized="state.asset_forms[asset.uid] = $event"
        />
        <hr v-if="index !== (filtered_assets.length - 1)">
      </div>
    </div>
  </div>
</template>
